.App {
  text-align: center;
}

.emoji img {
  width: 100px;
}

.emoji-list {
  display: flex;
  margin: 0px auto;
  justify-content: center;
}

.circle img {
  width: 150px;
}

.circles {
  display: flex;
}

.circle {
  width: 150px;
  height: 150px;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  box-shadow: 0px 0px 5px 2px #0ff;
}

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}

.circles {
  @include on-circle($item-count: 6, $circle-size: 20em, $item-size: 10em);
  margin: 5em auto 0;
  margin-top: 150px;
  margin-bottom: 100px;

  .circle {
    display: block;
    max-width: 100%;
    border-radius: 50%;
    border: solid 5px white;
    transition: 0.15s;

    &:hover,
    &:active {
      filter: grayscale(0);
    }
  }
}

.start-button {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  width: 200px;
  height: 100px;
  background-color: #44c767;
  border-radius: 14px;
  border: 4px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 23px;
  padding: 22px 31px;
  text-decoration: none;
  text-shadow: 0px 2px 0px #2f6627;
}
.start-button:hover {
  background-color: #5cbf2a;
}
